const CLASS_SELECTOR = ".PackSelector";
const CHILD_SELECTOR = ".PackSelectorItem";
const CSS_ACTIVE = "is-active";
const PRODUCT = document.querySelector('.Grid--product');
const instances = [];

function init(PageComponents, container = null) {
    if (!container || !container.querySelectorAll) {
        container = document;
    }
    const PackSelectors = container.querySelectorAll(CLASS_SELECTOR);
    PackSelectors.forEach((selectorElement) => {
        let activeSelectorIndex = 0;
        if (!selectorElement.hasAttribute('data-initialized')) {
            selectorElement.setAttribute('data-initialized', 'true');
            const childElements = selectorElement.querySelectorAll(CHILD_SELECTOR);
            const selectorTarget = selectorElement.getAttribute("data-target");
            if (selectorTarget) {
                const targetElements = document.querySelectorAll(selectorTarget);
                if (!targetElements) {
                    console.error(`Error: could not find content target "${selectorTarget}" for pack selector`, selectorElement);
                    return;
                }

                if (targetElements.length !== childElements.length) {
                    console.error(`Error: target element mismatch (${targetElements.length} vs ${childElements.length}) for pack selector`, selectorElement);
                    return;
                }



                function syncSelectors(index) {
                    function setIndexActive(element, i) {
                        element.classList.toggle(CSS_ACTIVE, i === index);
                    }

                    childElements.forEach(setIndexActive);

                    if(PRODUCT) {
                        var targetElements = document.querySelectorAll(selectorTarget);
                        var ProductSelectorActions = PRODUCT.querySelectorAll('.ProductSelectorAction');
                        var ProductBundleGrid = PRODUCT.querySelectorAll('.ProductBundleGrid-tiles');

                        ProductSelectorActions.forEach(setIndexActive);
                        ProductBundleGrid.forEach(setIndexActive);
                    }
                }

                function syncContent(index) {
                    targetElements.forEach((content, i) => {
                        content.classList.toggle(CSS_ACTIVE, i === index);
                    });
                }

                function sync(index) {
                    syncSelectors(index);
                    syncContent(index);
                    activeSelectorIndex = index;
                }

                childElements.forEach((selector, index) => {
                    if (selector.classList.contains(CSS_ACTIVE)) {
                        activeSelectorIndex = index;
                    }

                    // Add item click handler
                    selector.addEventListener('click', (e) => {
                        e.stopPropagation();
                        sync(index);
                    });
                });
                if (activeSelectorIndex > -1) sync(activeSelectorIndex);

                instances.push({
                    childElements,
                    targetElements,
                    sync
                });
            }
        }
    });
}

module.exports = {
    instances,
    init
};
